.exHeader {

  @media (min-width: 992px) {
    display: none;
  }
  background-color: #00C4F4;
  height: auto;
  padding: 18px 20px;
  line-height: normal;

  .switch {
    display: flex;
    align-items: center;
  }
}

.logoWrapper {
  display: flex;
  flex-direction: row;

  .logo {
    width: 48px;
    height: 60px;
    margin-right: 12px;
  }

  .logoName {
    display: flex;
    flex-direction: column;
    text-transform: uppercase;

    .number {
      font-weight: 800;
      font-size: 20px;
      line-height: 24px;
      color: #fff;
    }

    .title {
      font-weight: 800;
      font-size: 20px;
      line-height: 24px;
      color: #090909;
    }

    .subtitle {
      font-weight: 600;
      font-size: 10px;
      line-height: 12px;
      color: #090909;
    }
  }
}

.headerLogo {
  display: flex;
  align-items: center;
}

.headerTitle {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.85);
}

.headerItemsWrapper {
  display: flex;
  justify-content: space-between;
}

.userNameWrapper {
  align-items: center;
  gap: 10px;
  display: none;

  @media (min-width: 992px) {
    display: flex;
  }
}

@border-radius-base: 6px;@btn-disable-bg: #E5EDFB;@btn-height-base: 34px;@input-height-base: 34px;@primary-color: #00C4F4;@warning-color: #DE6826;@layout-header-background: #2A2A2A;@layout-trigger-background: #3A3A3A;@layout-body-background: #fefefe;