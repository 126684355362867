.siderHeader {
  display: flex;
  flex-direction: row;
  background-color: #00C4F4;
  height: auto;
  padding: 18px 20px;
  line-height: normal;

  button {
    @media (min-width: 992px) {
      display: none !important;
    }
    position: absolute;
    right: 16px;
  }
}

.sider {
  position: fixed;
  z-index: 100;
  :global {
    .ant-layout-sider {
      min-width: auto !important;
      width: auto !important;
      position: fixed;
      overflow-y: auto;
      height: 100dvh;
      background-color: #fff;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1), 5px 0px 20px rgba(0, 0, 0, 0.1);
    }
  }
}

:global(.ant-layout-sider-children) {
  display: flex;
  flex-direction: column;
}

.sponsorsWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .sponsors {
    border-top: 1px solid #D3D3D3;
    padding: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: #282828;
  }

  .sponsorLogos {
    gap: 15px;
    display: flex;
    margin-top: 20px;

    @media (min-width: 355px) {
      gap: 25px;
    }

    img {
      object-fit: contain;
    }
  }
}

.navigationMenu {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-top: 14px;

  button {
    height: auto;
    text-align: left;
    border-radius: 0px;
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
    color: #282828;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
  }
}

.loginName {
  height: auto;
  text-align: left;
  border-radius: 0px;
  font-weight: 800;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  color: #282828;
  padding-bottom: 10px;
  width: 100%;
}

.loginLabel {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #282828;
  text-transform: uppercase;
}

.languagePicker {
  margin-top: 40px;
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;

  .flagNameWrapper {
    display: flex;
    align-items: center;
  }

  .nameWrapper {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    align-items: flex-start;
  }

  .name {
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    color: #282828;
    display: flex;
    align-items: center;
  }

  .selected {
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: #282828;
    margin-left: 12px;
  }

  .nameLabel {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #282828;
  }

  .remainingLanguages {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: all .2s;
  }

  .language {
    padding-left: 0px;
    padding-right: 0px;
    border-radius: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: auto;
    text-transform: uppercase;
    padding-top: 12px;
  }

  .currentLanguage {
    flex: 1;
    justify-content: space-between;
    border-bottom: 1px solid #D3D3D3;

    svg {
      transition: all .2s;
    }
  }
}

.checkboxText {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #090909;
  margin-right: 15px;
}


.checkboxWrapper {
  display: flex;
  align-items: center;
  margin-top: -10px;
}

@border-radius-base: 6px;@btn-disable-bg: #E5EDFB;@btn-height-base: 34px;@input-height-base: 34px;@primary-color: #00C4F4;@warning-color: #DE6826;@layout-header-background: #2A2A2A;@layout-trigger-background: #3A3A3A;@layout-body-background: #fefefe;