body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.zone-label {
  font-size: 16pt;
  line-height: 18pt;
  font-family: din-2014, Roboto, sans-serif;
  font-weight: normal;
  text-align: left;
  padding-top: 8pt;
  padding-bottom: 10pt;
}

.zone-name {
  text-transform: uppercase;
  font-weight: bold;
}

.zone-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #fff;
  border-width: thin;
  border-color: #123247;
  border-style: solid;
  border-radius: 0px;
  gap: 8px;
  padding-top: 10pt;
  padding-left: 10pt;
  padding-right: 10pt;
  color: #123247;
  box-shadow: #123247 2px 2px 0px 2px;
}

.zone-container:hover {
  cursor: pointer;
}

.zone-wrapper-a {
  max-width: 130px;
  z-index: 1;
}

.zone-wrapper-b {
  max-width: 155px;
  z-index: 1;
}

.zone-wrapper-c {
  max-width: 180px;
  z-index: 1;
}

.zone-wrapper-d {
  max-width: 150px;
  z-index: 1;
}

.zone-wrapper-e {
  max-width: 205px;
  z-index: 1;
}

.zone-wrapper-f {
  max-width: 130px;
  z-index: 1;
}

.zone-wrapper-g {
  max-width: 120px;
  z-index: 1;
}

.maplibregl-popup {
  z-index: 3;
  font-size: 14pt;
  padding: 0;
  border-radius: 0;
  border-width: thin;
  border-color: #123247;
  border-style: solid;
  box-shadow: #123247 2px 2px 0px 2px;
  font-family: 'din-2014';
}

.maplibregl-popup-tip {
  display: none;
}

.maplibregl-popup .maplibregl-popup-content {
  padding: 20px;
}

.maplibregl-popup-close-button {
  font-size: 24px;
  color: #123247;
}

.zone-identifier {
  font-family: din-2014, Roboto, sans-serif;
  font-weight: bold;
  font-size: 50pt;
  padding-top: 10pt;
}

#root > section:not(.ant-layout-has-sider) > section > main {
  display: flex;
}

.illustration {
  pointer-events: none;
  z-index: 1;
}

.illustration-hand {
  z-index: 2;
}

.illustration-stars {
  z-index: 2;
}

.illustration-apple {
  z-index: 2;
}
