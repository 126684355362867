.approvals {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #282828;
}

.submitButton {
  width: 100%;
  background: #368BD6;
  min-height: 52px;
  border-radius: 0px;
  margin-top: 10px;
  font-size: 16px;
  font-weight: 500;

  &:disabled {
    background: #EEEEEE;
    color: #AAAAAA;
  }
}

:global(.ant-checkbox-inner) {
  border-radius: 0;
}

.registerFormInput {
  border: 1px solid #EEEEEE;
  border-radius: 0px;
  min-height: 52px;
  color: #282828;
  font-size: 16px;
}

.registerFormInput:hover {
  border: 1.5px solid #368BD6;
  box-shadow: unset;

}

.checkbox {
  margin-right: 16px;

  :global(.ant-radio-inner) {
    width: 24px;
    height: 24px;

    &::after {
      width: 12px;
      height: 12px;
    }
  }

  :global(.ant-checkbox-inner) {
    width: 24px;
    height: 24px;

    &::after {
      width: 7px;
      height: 12px;
      margin-left: 1.5px;
    }
  }

  :global(.ant-radio,
  .ant-checkbox) {
    font-size: 24px;
  }

  :global(.ant-checkbox-checked .ant-checkbox-inner) {
    background-color: #282828;
    border-color: #282828;
  }

  :global(.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner) {
    border-color: black;
  }

  :global(.ant-checkbox-input:focus + .ant-checkbox-inner) {
    box-shadow: none;
    outline: none;
  }

  :global(.ant-checkbox-checked::after) {
    border: unset;
  }
}

.checkboxWrapper {
  display: flex;
  align-items: center;
  margin-top: -10px;
}

.checkboxText {
  margin-top: 2px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #090909;
}

.registerWrapper {
  :global(.ant-input::placeholder) {
    color: #AAAAAA;
    opacity: unset;
  }

  :global(.ant-btn[disabled]) {
    border: unset;
    background-color: #EEEEEE;
    color: #AAAAAA;
  }

  :global(.ant-btn) {
    padding: unset !important;
  }

  :global(.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus) {
    color: #D9132B;
    border: 1.5px solid #D9132B !important;
    box-shadow: unset;
  }

  :global(.ant-input) {
    color: inherit;
  }

  :global(.ant-form-item-explain-error) {
    margin-bottom: 10px;
    color: #D9132B;
  }

  :global(.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover) {
    border: 1.5px solid #368BD6;
    box-shadow: unset;
  }

  :global(.ant-btn-primary:hover) {
    background-color: #EEEEEE;
    border: unset;
    color: #AAAAAA;
  }

  :global(.ant-form-item) {
    margin-bottom: 16px;
  }


  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }
}

@border-radius-base: 6px;@btn-disable-bg: #E5EDFB;@btn-height-base: 34px;@input-height-base: 34px;@primary-color: #00C4F4;@warning-color: #DE6826;@layout-header-background: #2A2A2A;@layout-trigger-background: #3A3A3A;@layout-body-background: #fefefe;