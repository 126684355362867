.modules-sider .ant-table-tbody > tr > td {
  border-bottom: 0px solid #ffffff;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.section-panel-collapse .section-panel-panel > div.ant-collapse-header {
  & > span.anticon {
    height: 100%;
    display: flex;
    align-items: center;
    top: 0;
    padding-top: unset;
  }

  & > div.localizable-readonly {
    display: inline-block;
  }
}

.ant-layout-content {
  margin: 0 20px;
}

body {
  font-family: 'Barlow', 'Inter', sans-serif;
}

@border-radius-base: 6px;@btn-disable-bg: #E5EDFB;@btn-height-base: 34px;@input-height-base: 34px;@primary-color: #00C4F4;@warning-color: #DE6826;@layout-header-background: #2A2A2A;@layout-trigger-background: #3A3A3A;@layout-body-background: #fefefe;